import { Button, Container, Text, Textarea, Card, Row } from "@nextui-org/react";
import { Input, Spacer } from "@nextui-org/react";
import { Xor } from "../../_components/xor";

export function PublicXor() {

	return <Xor>
		<Container css={{
			position: "static",
			textAlign: "center",
		}}>
			<Spacer y={2} />
			<Text h2>crmnl | executor</Text>
			<Text>Inject into the RageMP loadingscreen or inject ingame and reconnect once.</Text>
			<Text>We don't support the Win11 Version 22H2</Text>
			<Spacer y={2} />
			<Text>If you got "error loading driver" restart your pc or deactivate anticheats like vanguard or faceit</Text>
			<Text>Don't ask for scripts in our discord. guess what, you won't get any just for asking</Text>
			<Spacer y={2} />
			<Text>I think this wiki is the best you've ever read, huh?</Text>
		</Container>
	</Xor>;

}


