import { Container, Grid, Input, Spacer } from "@nextui-org/react";


export function LoginPage() {
    return <Container>
        <Grid.Container direction={"column"} justify="center" alignItems={"center"} css={{height: '100vh'}}>
            <form autoComplete={"none"}>
                <Grid.Container direction={"column"} justify="center" alignItems={"center"}>
                    <Input placeholder="Username or Email" />
                    <Spacer y={1} />
                    <Input placeholder="Password" type={"password"} />
                </Grid.Container>
            </form>
        </Grid.Container>
    </Container>
}