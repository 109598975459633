


export function PanelHome() {

    return (

        <div>
            hello from Panel Home page!
        </div>

    );
}