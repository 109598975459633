import { icons } from "./icons/icons"

export interface Navigation {
    label: string,
    link?: string,
    icon?: any,
    desc?: string,
    childrens?: Navigation[]
}


export const publicNav: Navigation[] = [
    { label: 'Home', link: '/' },
    { label: 'Wiki', link: '/wiki' },
    {
        label: 'Test Sub', childrens: [
            {
                label: 'test #1',
                link: '/about',
                icon: icons.activity,
                desc: 'Hello world, we are the best haxors in world'
            }
        ]
    }
]

export const ucpNav: Navigation[] = [
    { label: 'Dashboard', link: '/u/' },
    {
        label: 'Test Sub', childrens: [
            {
                label: 'test #1',
                link: '/u/about',
                desc: 'Hello world, we are the best haxors in world'
            }
        ]
    }
];