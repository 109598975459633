import {createTheme, NextUIProvider } from "@nextui-org/react";
import { ThemeProvider } from "next-themes";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { Panel } from "./layouts/panel";
import { Public } from "./layouts/public";
import { PanelHome } from "./pages/panel/home";
import { LoginPage } from "./pages/panel/login";
import { PublicHome } from "./pages/public/home";
import { PublicXor } from "./pages/public/xor";
import { NotFound } from "./_components/404";
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import "./app.css";

const lightTheme = createTheme({
    type: 'light',
})

const darkTheme = createTheme({
    type: 'dark',
    theme: {
    }
})

function DiscordWeiterleitung() {
    return <Helmet>
        <meta property="og:site_name" content="Official Community Discord" />
        <meta property="og:url" content="https://discord.gg/DAYmDBeVKR" />
        <meta property="og:title" content="crmnl | cafe" />
        <meta property="og:description" content="The all-in-one Modification for GTAV" />
        <meta property="og:image" content="https://cdn.discordapp.com/attachments/906248962137280529/978024240932593674/o4mgoG6.png" />
        <meta http-equiv="refresh" content="0; url=https://discord.gg/DAYmDBeVKR" />
        <meta name="theme-color" content="#ff425b" />
    </Helmet>
    
}

export function Application() {
    return (
        <ThemeProvider
            defaultTheme="dark"
            attribute="class"
            value={{
                light: lightTheme.className,
                dark: darkTheme.className
            }}
        >
            <NextUIProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path={"/u/login"} element={<LoginPage />}/>

                        <Route path={"/u"} element={<Panel />}>
                            <Route path={"/u/"} element={<PanelHome />} />
                            <Route path={"/u/*"} element={<NotFound />} />
                        </Route>

                        <Route path={"/"} element={<Public />}>
                            <Route path={"/"} element={<PublicHome />}/>
                            <Route path={"/wiki"} element={<PublicXor />}/>
                            <Route path={"*"} element={<NotFound />} />
                        </Route>

                        <Route path="/discord" element={<DiscordWeiterleitung />} />
                    </Routes>
                </BrowserRouter>
            </NextUIProvider>
        </ThemeProvider>
    );
}