import { Container, Text, Navbar } from "@nextui-org/react";
import { Cover } from "../../_components/cover";

import '../../_components/cover.css';

export function PublicHome() {
    document.title = "crmnl.pw";
    return <div className="video-wrapper">
        <div className="content">
            <h1>crmnl.pw</h1>
        </div>
        <div className="video-pattern"></div>
        <div className="background">
            <video src={"video.mp4"} autoPlay loop muted />
        </div>
    </div>;

}


