import { Button, Container, Dropdown, Navbar, Text, Avatar, Input } from "@nextui-org/react";
import { Key } from "react";
import { useNavigate, useOutlet, useLocation } from "react-router-dom";
import { UserPanel } from "../_components/userPanel";
import { icons } from "../icons/icons";
import { publicNav } from "../navigation";


export function Public() {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();


  return <>
    <Navbar 
      containerCss={{
        position: 'relative',
        justifyContent: "start",
      }} 
      css={{
        background: 'transparent'
      }}
      isBordered
      variant={"floating"}
    >
      <Navbar.Brand
        css={{
          position: 'absolute',
          top: '50%',
          left: 20,
          transform: 'translate(0, -50%)'
        }}
      >
        <Text b color="inherit" css={{ mr: "$11" }} hideIn="xs">
          CRMNL
        </Text>
      </Navbar.Brand>
      <Navbar.Content css={{width: '100%', justifyContent: 'center'}} activeColor="secondary" hideIn="xs" variant="highlight">
        {
          publicNav.map((nav) => {
            if (nav.childrens && nav.childrens.length != 0) {
              return
            }
            return <Navbar.Link isActive={nav.link === location.pathname} onPress={() => navigate({ pathname: nav.link })}>{nav.label}</Navbar.Link>
          })
        }
      </Navbar.Content>
    </Navbar>
    {outlet}
  </>;
}